import { forwardRef, HTMLProps } from "react";
import clsx from "clsx";
import { Icon } from "~/components/ui/icons";

type SelectProps = HTMLProps<HTMLSelectElement>;
type SelectOptionProps = HTMLProps<HTMLOptionElement>;
type SelectOptionGroupProps = HTMLProps<HTMLOptGroupElement>;

export function Option({ value, children, ...props }: SelectOptionProps) {
	return (
		<option value={value} {...props}>
			{children}
		</option>
	);
}

export function OptGroup(props: SelectOptionGroupProps) {
	return <optgroup {...props}>{props.children}</optgroup>;
}
// className="text-primary border-stroke bg-primary h-[42px] w-full appearance-none rounded border px-5 py-3 font-body outline-none"

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
	function Select(props, ref) {
		const classNames = clsx(
			"border-stroke bg-primary h-[42px] w-full appearance-none rounded border px-5 py-3 focus-visible:border-grey-80 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-30",
			props.className
		);
		return (
			<div className="relative">
				<select {...props} className={classNames} ref={ref} />
				<Icon
					name="chevron-down"
					color="secondary"
					className="pointer-events-none absolute right-5 top-4 size-5"
				/>
			</div>
		);
	}
);
